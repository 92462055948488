import Logo from '../assets/logo2.png';
import {Container, Image, Nav, Navbar } from 'react-bootstrap';
import { useState } from 'react';
import { color2 } from '../componentes/config.js'

function Menu() {
  return (
    <Navbar 
      collapseOnSelect expand="lg" 
      // className="bg-body-tertiary" 
      style={{ backgroundColor: color2 }}
      // onSelect={(selectedKey) => mostrarVentana(`${selectedKey}`)}
    >
      <Container style={{ background:color2}}>
        <Navbar.Brand href="#">
          <Image src={Logo} fluid />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ background:'white', color:'white'}}/>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" style={{ color:'white'}}>
          <Nav className="justify-content-end" >
            <Nav.Link  href="#div_lineaTiempo" style={{ color:'white'}}>¿Cómo utilizar el servicio?</Nav.Link>
            <Nav.Link href="#div_registro" style={{ color:'white'}}>Registrarme</Nav.Link>
            <Nav.Link href="#div_contactenos" style={{ color:'white'}}>Contactenos</Nav.Link>
              
            <Nav.Link 
              href="https://api.whatsapp.com/send?phone=573028551507&amp;text=Hola,%20requiero%20mas%20información..." 
              target="_blank" 
              rel="noreferrer" 
              style={{ color:'white'}}
            >WhatsApp</Nav.Link>
              
            <Nav.Link href='https://play.google.com/store/apps/details?id=com.taxysmoto.movil' target="_blank" style={{ color:'white'}}>Descargar App</Nav.Link>
            <Nav.Link  href="#div_politicas" style={{ color:'white'}}>Política de Privacidad</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      {/* <Mvto show={show} handleClose={handleClose} ventana={ventana} panel={'historia'}/>  */}
    </Navbar>
  );
}

export default Menu;