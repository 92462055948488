import { useState } from "react";
import { FaPhoneVolume } from "react-icons/fa6";
import { BsGeoAlt } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { FaFacebook } from "react-icons/fa";
import { LuInstagram } from "react-icons/lu";
import { FaWhatsapp } from "react-icons/fa";
import { color1, color3 } from '../componentes/config.js';



const PiePagina=()=>{
  return(
    <div className="container mt-3">
      <div className="row">

        <div className="col-sm-4">
          <h5 style={{color:'#7ff'}}>Descargar App</h5>
          <hr />
          <p className="p-0" style={{color:color1}}> <BsGeoAlt />
            <a href='https://play.google.com/store/apps/details?id=com.taxysmoto.movil' 
              target="_blank" 
              rel="noreferrer" 
              style={{ color:color1}}
              > Google Play
            </a>
          </p>
        </div>


        <div className="col-sm-4">
          <h5 style={{color:'#7ff'}}>Contacto</h5>
          <hr />
          <p className="p-0" style={{color:color1}}><FaPhoneVolume /> 302-8551507</p>
          <p className="p-0" style={{color:color1}}><FaWhatsapp /> 302-8551507</p>
          <p className="p-0" style={{color:color1}}><CiMail /> jdpadillac@gmail.com</p>
        </div>

       

        <div className="col-sm-4">
          <h5 style={{color:'#7ff'}}>Redes Sociales</h5>
          <hr />
          <ul 
            className='p-2' 
            style={{listStyle:'none', textDecoration:'none'}}>
            <li>
              <a 
                href='https://www.facebook.com' 
                target="_blank" 
                rel="noreferrer" 
                style={{color:color1,textDecoration:'none'}}><FaFacebook /> Facebook
              </a>
            </li>
            <li>
              <a 
                href='https://www.instagram.com/' 
                target="_blank" 
                rel="noreferrer" 
                style={{color:color1,textDecoration:'none'}}><LuInstagram /> Instagram
              </a>
            </li>
            <li>
              <a href="https://api.whatsapp.com/send?phone=573028551507&amp;text=Hola,%20requiero%20mas%20información..." 
                target="_blank" 
                rel="noreferrer" 
                style={{color:color1,textDecoration:'none'}}><FaWhatsapp /> WhatsApp
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <hr />
        <p style={{ fontSize:12}}><a href="https://www.linkedin.com/in/josedavidpadillacardenas/" target='_blank' style={{color:'#7ff',textDecoration:'none'}}>Copyright © 2024 Designed by JDPC Software</a></p>
      </div>
    </div>
  )
}

export default PiePagina