import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from './paginas/Menu.jsx';
import Slider from './paginas/Slider.jsx';
import Galeria from './paginas/Galeria.jsx';
import Registro from './paginas/Registro.jsx';
import Contactenos from './paginas/Contactenos.jsx';
import Pie from './paginas/PiePagina.jsx';
import Pasos from './paginas/Pasos.jsx';
import LineaTiempo from './paginas/LineaTiempo.jsx';
import Politicas from './paginas/Politicas.jsx'
import Accordion from 'react-bootstrap/Accordion';
import { color1, color2, color3, TITULO } from './componentes/config.js'


function App() {
  return (
    
    <div className="container-fluid" id='div_menu'>
      <div className="row">
        <Menu/>
      </div>

      <div className="row mb-3" id='div_slider'>
        <Slider/>
      </div>

      <div className='row' style={{ marginTop:100,}}></div>

      <div className="row mt-3" style={{ backgroundColor: color1}} id='div_pasos'>
        <Pasos />
      </div>
       
      <div className="row text-center mt-3 mb-3" id='div_lineaTiempo' style={{ backgroundColor: color2}}>
        <hr />
        <h2 className='mt-3' style={{ color:'#7ff'}}>LINEA DE TIEMPO DEL SERVICIO</h2>
        <hr />
        <LineaTiempo />
      </div>

      <div className="row text-center mt-3 mb-3" id='div_galeria'>
        <Galeria />
      </div>

      <div className="row text-center pt-3 mt-3 mb-5" id='div_registro' style={{ backgroundColor: color2}}>
        <Registro />
      </div>

      <div className="row text-center pt-3 mt-3 mb-5" id='div_contactenos' >
        <Contactenos />
      </div>

      <div className="row text-center pt-3 mt-3" id='div_politicas'>
            <Accordion className='mb-3'>
              <Accordion.Item eventKey="0">
                <Accordion.Header><strong>Política de Privacidad de {TITULO}</strong></Accordion.Header>
                <Accordion.Body>
                  <Politicas />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
      </div> 

      <div className="row" style={{ backgroundColor: color2}}>
        <Pie />
      </div>
    </div>
  );
}


export default App;