
import Button from 'react-bootstrap/Button';
import Modal1 from 'react-bootstrap/Modal';
import Logo from '../assets/logo.png'
import { TITULO } from '../componentes/config.js';


function Modal({show, handleClose, msg, boton, pregunta}) {
  const renderList = msg.map((item, index) => 
    <div key={index}>{item}</div>
  );

  const respuesta =()=>{
    handleClose(true)
    pregunta()
  }

  return (<>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal1 show={show} onHide={handleClose}>
        <Modal1.Header closeButton>
          <img src={Logo} className='img-fluid' width='80px' height='80px' alt='logo' style={{ borderRadius:10}}/>
          {/* <Modal1.Title>{' '} {' ' + TITULO}</Modal1.Title> */}
        </Modal1.Header>
        <Modal1.Body>
          <ul>{renderList}</ul>
          </Modal1.Body>
        <Modal1.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          {boton!==undefined && boton!=='' &&
            <Button variant="primary" onClick={respuesta}>
              {boton}
            </Button>
          }
        </Modal1.Footer>
      </Modal1>
    </>
  );
}

export default Modal