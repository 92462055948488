export const TITULO = 'ReDeTrIn!';
export const PORT_SERVER = 4000;
export const color1 = '#E5E5E5';
export const color2 = '#3a7ca5';
export const color3 = '#7fff00';  //Verde

//export const RUTA_SERVER = "https://backendmotos.onrender.com"; //Servidor de render
export const RUTA_SERVER = 'http://192.168.1.1:4000';    //


//Local desde el celular hacía el PC en una LAN
/* export const RUTA_SERVER = 'http://192.168.1.2:4000';    //Local desde mi portatil en la LAN
export const RUTA_PATROCINADOR  = 'http://192.168.1.2:4000/patrocinadores/';
export const RUTA_COLABORADOR   = 'http://192.168.1.2:4000/colaboradores/'; 
 */
 

  