
import { Card } from 'react-bootstrap';
import persona from '../assets/persona.png';
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { PiHandPointingFill } from "react-icons/pi";
import { color1,  } from '../componentes/config.js'
import { BsFillSuitHeartFill } from "react-icons/bs";

const fontStyles = {fontSize: '80px', color: '#000050'};

const Pasos=()=>{
  return(
    <div className="container-fluid mt-3" >
      <div className="row">
        <div className="col-sm-6">
          <img src={persona}  style={{ maxHeight:800, marginTop:-100}}/>
        </div>

        <div className="col-sm-6 mt-3 pt-5">
          <h1><MdOutlinePhoneAndroid style={fontStyles}/> SOLICITE</h1>
          <address style={{ color:'#000050'}}>Cuentenos la necesidad que tiene, ya sea que necesita una reparación de plomería, electricidad, albañilería, desarrollador de software, abogado, contador, en fin...a la cual hay que darle solución.<br />Especifique la fecha, hora y lugar donde se requiere el servicio.</address>
          <br />
          <h1><PiHandPointingFill style={fontStyles}/> RECIBA</h1> 
          <address style={{ color:'#000050'}}>Reciba las propuesta del personal calificado que tenemos en nuestra base de datos.<br />Mire las calificaciones de cada Proponente y seleccione la propuesta que más le parezca conveniente.</address>
          <br />
          <h1><RiMoneyDollarCircleFill style={fontStyles}/> NEGOCIE</h1>
          <address style={{ color:'#000050'}}>Negocie con el prestador de servicio y pacte una tarifa.<br />Es probable que el servicio pueda generar costos adicionales de acuerdo a la solución que necesite.</address>
          <br />
          <h1><BsFillSuitHeartFill style={fontStyles}/> SOLUCIÓN EFECTIVA</h1>
          <address style={{ color:'#000050'}}>Espere la llegado del prestador y sientase tranquilo de tener una solución efectiva, segura y garantizada!.</address>
        </div>
      </div>
    </div>
  )
}

export default Pasos