import {Card } from 'react-bootstrap';
import banner1 from '../assets/slider.jpg';

const Slider=()=>{
  return(
    <Card className="p-0 mb-3">
      <Card.Img src={banner1} alt="Banner"/>
      <Card.ImgOverlay className='mt-3'>
        
        <Card.Title style={{color:'#000050'}}><p style={{ fontSize:50}}>Somos la luz que lo ilumina!</p></Card.Title>
        <Card.Subtitle style={{color:'#000050'}}>
          <h2>{`Solucionamos su necesidad de forma rápida y confiable!!`}</h2>
        </Card.Subtitle>
      </Card.ImgOverlay>
    </Card>
  )
}

export default Slider