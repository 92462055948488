import * as React from 'react';
import Alert from 'react-bootstrap/Alert';
import { TITULO, color1 } from '../componentes/config';

const Politicas=()=>{
  return(
    <div className="container" style={{ background: color1}}>
      <div className="row">
        <Alert variant="suinfoccess">
          <Alert.Heading><strong className='text-muted'>POLITICA DE PRIVACIDAD DE {TITULO}</strong></Alert.Heading>
          <p>
            Esta Aplicación recopila algunos Datos Personales de sus <strong>Usuarios.!</strong>
          </p>
          <hr />
        </Alert>
      </div>

      <div className="row">
        <p>Este documento se puede imprimir como referencia utilizando el comando de impresión en la configuración de cualquier navegador.
        </p>
        <p><strong>JOSE DAVID PADILLA CARDENAS</strong></p>
        <p>Cisneros, Antioquia - Colombia</p>
        <p>Calle 23 Carrera 38 Apto 103</p>
        <p>Correo electrónico de contacto del propietario: jdpadillac@gmail.com</p>
      </div>

      <div className="row">
        <p><strong>Tipos de datos recopilados</strong></p>
        <p>Entre las clases de Datos Personales que recoge esta Aplicación, ya sea directamente o a través de terceros, se encuentran: Permiso de contactos; Permiso de cámara; Permiso de ubicación aproximado (continuo); Datos de uso; dirección de correo electrónico; contraseña.</p>
        <p>Los detalles completos sobre cada tipo de Datos personales recopilados se proporcionan en las secciones dedicadas de esta política de privacidad o mediante textos explicativos específicos que se muestran antes de la recopilación de Datos. Los Datos personales pueden ser libremente proporcionados por el Usuario o, en el caso de Datos de uso, recopilados automáticamente al utilizar esta Aplicación. A menos que se especifique lo contrario, todos los Datos solicitados por esta Aplicación son obligatorios y no proporcionarlos puede hacer imposible que esta Aplicación proporcione sus servicios. En los casos en que esta Aplicación indique específicamente que algunos Datos no son obligatorios, los Usuarios son libres de no comunicar estos Datos sin consecuencias para la disponibilidad o el funcionamiento del Servicio.</p>
        <p>Los Usuarios que no están seguros de qué Datos Personales son obligatorios,  pueden ponerse en contacto con el Propietario.</p>
        <p>Cualquier uso de Cookies (o de otras herramientas de seguimiento) por parte de esta Aplicación o por los propietarios de servicios de terceros utilizados por esta Aplicación tiene el propósito de prestar el Servicio requerido por el Usuario, además de cualesquiera otras finalidades descritas en el presente documento.</p>
        <p>Los usuarios son responsables de los Datos personales de terceros obtenidos, publicados o compartidos a través de esta Aplicación.</p>
      </div>

      <div className="row">
        <p><strong>Modo y lugar de procesamiento de los Datos</strong></p>
        <p><strong>Métodos de procesamiento</strong></p>
        <p>El Propietario toma las medidas de seguridad adecuadas para evitar el acceso no autorizado, la divulgación, la modificación o la destrucción no autorizada de los Datos.
El procesamiento de los Datos se lleva a cabo utilizando computadoras, celulares y/o herramientas habilitadas para TI , siguiendo procedimientos y modalidades organizativos estrictamente relacionados con las finalidades indicadas. Además del Titular, en algunos casos, los Datos pueden ser accesibles a ciertos tipos de personas responsables, involucradas con el funcionamiento de esta Aplicación (administración, ventas, marketing, legal, administración de sistemas) o partes externas (como terceros). Terceros proveedores de servicios técnicos, proveedores de hosting, empresas informáticas, agencias de comunicación designados, en su caso, como Encargados del Tratamiento por el Titular. La lista actualizada de dichas partes podrá solicitarse al Titular en cualquier momento.
        </p>

        <p><strong>Lugar</strong></p>
        <p>Los Datos son tratados en las oficinas operativas del Titular y en cualesquiera otro lugar donde se encuentren las partes involucradas en el tratamiento.
Dependiendo de la ubicación del Usuario, las transferencias de datos pueden implicar la transferencia de los Datos del Usuario a un país distinto al suyo. Para obtener más información sobre el lugar de procesamiento de dichos Datos transferidos, los Usuarios pueden consultar la sección que contiene detalles sobre el procesamiento de Datos Personales.
        </p>

        <p><strong>Tiempo de retención</strong></p>
        <p>A menos que se especifique lo contrario en este documento, los Datos Personales se procesarán y almacenarán durante el tiempo que lo requiera el propósito para el que fueron recopilados y podrán conservarse por más tiempo debido a la obligación legal aplicable o con base al consentimiento de los Usuarios.
        </p>

        <p><strong>Los fines del procesamiento</strong></p>
        <p>Los Datos relativos al Usuario se recogen para permitir al Titular prestar su Servicio, cumplir con sus obligaciones legales, responder a solicitudes de ejecución, proteger sus derechos e intereses (o los de sus Usuarios o terceros), detectar cualquier actividad maliciosa o fraudulenta, así como lo siguiente: Permisos de dispositivos para acceso a Datos Personales, Análisis, Registro y autenticación, Acceso a cuentas de terceros, Registro y autenticación proporcionados directamente por esta Aplicación y Publicidad.
Para información específica sobre los Datos Personales utilizados para cada finalidad, el Usuario podrá consultar la sección “Información detallada sobre el tratamiento de Datos Personales”.
        </p>

        <p><strong>LOS PERMISOS SOLICITADOS SON LOS SIGUIENTES:</strong></p>
        <p><strong>Información básica</strong></p>
        <p>De forma predeterminada, esto incluye ciertos datos del usuario, como nombres y apellidos, número de celular, imagen y ubicación.
        </p>

        <p><strong>Correo electrónico</strong></p>
        <p>Proporciona acceso a la dirección de correo electrónico principal del Usuario.
        </p>

        <p><strong>Permisos del dispositivo para el acceso a datos personales</strong></p>
        <p>Dependiendo del dispositivo específico del Usuario, esta Aplicación puede solicitar ciertos permisos que le permitan acceder a los Datos del dispositivo del Usuario como se describe a continuación.
        </p>

        <p>De forma predeterminada, estos permisos deben ser otorgados por el Usuario antes de poder acceder a la información respectiva. Una vez otorgado el permiso, éste podrá ser revocado por el Usuario en cualquier momento. Para revocar estos permisos, los Usuarios pueden consultar la configuración del dispositivo o comunicarse con el Propietario para obtener ayuda mediante los datos de contacto proporcionados en el presente documento.
        </p>
        <p>El procedimiento exacto para controlar los permisos de la aplicación puede depender del dispositivo y el software del Usuario.
        </p>
        <p>Tenga en cuenta que la revocación de dichos permisos podría afectar el funcionamiento adecuado de esta Aplicación.
        </p>
        <p>Si el Usuario otorga cualquiera de los permisos que se enumeran a continuación, esta Aplicación puede procesar (es decir, acceder, modificar o eliminar) los Datos personales respectivos.
        </p>

        <p><strong>Permiso de ubicación aproximado (continuo)</strong></p>
        <p>Se utiliza para acceder a la ubicación aproximada del dispositivo del Usuario. Esta Aplicación puede recopilar, utilizar y compartir Datos de ubicación del Usuario para proporcionar servicios basados en la ubicación.
        </p>

        <p><strong>Permiso de cámara</strong></p>
        <p>Se utiliza para acceder a la cámara o capturar imágenes y videos desde el dispositivo.
        </p>

        <p><strong>Información detallada sobre el tratamiento de Datos Personales</strong></p>
        <p>Los Datos Personales se recopilan para las siguientes finalidades y utilizando los siguientes servicios:
        </p>

        <p><strong>•	Acceso a cuentas de terceros:</strong> Publicidad</p>
        <p><strong>•	Analítica:</strong> Permisos del dispositivo para el acceso a datos personales</p>
        <p><strong>•	Registro y autenticación:</strong> Registro y autenticación proporcionados directamente por esta Aplicación</p>

        <p><strong>Información sobre cómo darse de baja de la publicidad basada en intereses</strong></p>
        <p>Además de cualquier función de exclusión voluntaria proporcionada por cualquiera de los servicios enumerados en este documento, los Usuarios pueden seguir las instrucciones proporcionadas por YourOnlineChoices (UE), la Iniciativa de Publicidad en Red (EE.UU.) y la Alianza de Publicidad Digital (EE.UU.), DAAC (Canadá), DDAI (Japón) u otras iniciativas similares. Estas iniciativas permiten a los usuarios seleccionar sus preferencias de seguimiento para la mayoría de las herramientas publicitarias. Por ello, el Titular recomienda a los Usuarios hacer uso de estos recursos además de la información proporcionada en este documento.
        </p>
        <p>La Digital Advertising Alliance ofrece una aplicación llamada AppChoices que ayuda a los usuarios a controlar la publicidad basada en intereses en aplicaciones móviles.
        </p>

        <p>Los usuarios también pueden optar por no recibir ciertas funciones publicitarias a través de la configuración aplicable del dispositivo, como la configuración de publicidad del dispositivo para teléfonos móviles o la configuración de anuncios en general.
        </p>

        <p><strong>Más información para los usuarios</strong></p>
        <p><strong>Base jurídica del tratamiento</strong></p>
        <p>El Titular podrá procesar Datos Personales relacionados con los Usuarios si se aplica uno de los siguientes:
        </p>
        <p>•	Los usuarios han dado su consentimiento para una o más finalidades específicas.
        </p>
        <p>•	el suministro de Datos es necesario para la ejecución de un acuerdo con el Usuario y/o para cualquier obligación precontractual del mismo;
        </p>
        <p>•	el procesamiento es necesario para el cumplimiento de una obligación legal a la que está sujeto el Titular;
        </p>
        <p>•	el procesamiento está relacionado con una tarea que se lleva a cabo en interés público o en el ejercicio de poderes oficiales conferidos al Titular;
        </p>
        <p>•	el procesamiento es necesario para los fines de los intereses legítimos perseguidos por el Titular o por un tercero.
        </p>
        <p>En cualquier caso, el Propietario estará encantado de ayudar a aclarar la base jurídica específica que se aplica al procesamiento y, en particular, si el suministro de Datos personales es un requisito legal o contractual, o un requisito necesario para celebrar un contrato.
        </p>

        <p><strong>Más información sobre el tiempo de retención</strong></p>
        <p>A menos que se especifique lo contrario en este documento, los Datos Personales se procesarán y almacenarán durante el tiempo que lo requiera el propósito para el que fueron recopilados y podrán conservarse por más tiempo debido a la obligación legal aplicable o en base al consentimiento de los Usuarios.
        </p>
        <p>Por lo tanto:</p>
        <p>•	Los datos personales recopilados para fines relacionados con la ejecución de un contrato entre el Propietario y el Usuario se conservarán hasta que dicho contrato se haya ejecutado en su totalidad.</p>
        <p>•	Los datos personales recopilados para los fines de los intereses legítimos del Propietario se conservarán durante el tiempo que sea necesario para cumplir dichos fines. Los usuarios pueden encontrar información específica sobre los intereses legítimos perseguidos por el Propietario en las secciones correspondientes de este documento o contactando al Propietario.</p>
        <p>Se podrá permitir al Propietario conservar Datos personales durante un período más largo siempre que el Usuario haya dado su consentimiento para dicho procesamiento, siempre y cuando dicho consentimiento no sea retirado. Además, el Titular puede verse obligado a conservar los Datos personales durante un período más prolongado siempre que sea necesario para cumplir con una obligación legal o por orden de una autoridad.</p>
        <p>Una vez que expire el período de retención, los Datos personales se eliminarán. Por lo tanto, el derecho de acceso, el derecho de supresión, el derecho de rectificación y el derecho a la portabilidad de los datos no pueden hacerse cumplir una vez transcurrido el período de retención.</p>
        <p><strong>Los derechos de los Usuarios con base al Reglamento General de Protección de Datos (GDPR)</strong></p>
        <p>Los usuarios podrán ejercitar determinados derechos respecto de sus Datos tratados por el Titular.</p>
        <p>En particular, los Usuarios tienen derecho a realizar, en la medida permitida por la ley, lo siguiente:</p>
        <p><strong>•	Retirar su consentimiento en cualquier momento. </strong>Los usuarios tienen derecho a retirar su consentimiento cuando previamente hayan dado su consentimiento para el procesamiento de sus Datos Personales.</p>
        <p><strong>•	Oponerse al procesamiento de sus Datos.</strong> Los usuarios tienen derecho a oponerse al procesamiento de sus Datos si el procesamiento se realiza sobre una base legal distinta del consentimiento.</p>
        <p><strong>•	Acceder a sus Datos. </strong>Los usuarios tienen derecho a saber si los Datos están siendo procesados por el Titular, obtener información sobre ciertos aspectos del procesamiento y obtener una copia de los Datos en proceso.</p>
        <p><strong>•	Verificar y solicitar rectificación. </strong>Los usuarios tienen derecho a verificar la exactitud de sus Datos y solicitar su actualización o corrección.</p>
        <p><strong>•	Restringir el procesamiento de sus Datos. </strong>Los usuarios tienen derecho a restringir el procesamiento de sus Datos. En este caso, el Titular no tratará sus Datos con ninguna finalidad distinta a la de almacenarlos.</p>
        <p><strong>•	Que se eliminen o eliminen de otro modo sus Datos personales. </strong>Los usuarios tienen derecho a obtener la eliminación de sus Datos del Propietario.</p>
        <p><strong>•	Recibir sus Datos y transferirlos a otro responsable del tratamiento. </strong>Los usuarios tienen derecho a recibir sus Datos en un formato estructurado, de uso común y legible por máquina y, si es técnicamente posible, a transmitirlo a otro responsable del tratamiento sin ningún obstáculo.</p>
        <p><strong>•	Presentar una reclamación. </strong>Los usuarios tienen derecho a presentar una reclamación ante su autoridad de protección de datos competente.</p>
        <p>Los usuarios también tienen derecho a conocer la base jurídica de las transferencias de Datos al extranjero, incluso a cualquier organización internacional regida por el derecho internacional público o creada por dos o más países, como la ONU, y sobre las medidas de seguridad adoptadas por el Titular para salvaguardar sus Datos.</p>

      <p><strong>Detalles sobre el derecho a oponerse al procesamiento
Cuando los Datos personales se procesan por un interés público, en el ejercicio de una autoridad oficial conferida al Titular o para los fines de los intereses legítimos perseguidos por el Titular, los Usuarios pueden oponerse a dicho procesamiento proporcionando un motivo relacionado con su situación particular para justificar la objeción.
</strong></p>
      <p><strong>Los usuarios deben saber que, sin embargo, si sus Datos Personales fueran tratados con fines de marketing directo, podrán oponerse a dicho tratamiento en cualquier momento, de forma gratuita y sin necesidad de justificación alguna. Cuando el Usuario se oponga al procesamiento con fines de marketing directo, los Datos personales ya no se procesarán para dichos fines. Para saber si el Propietario está procesando Datos personales con fines de marketing directo, los Usuarios pueden consultar las secciones pertinentes de este documento.</strong></p>
      
      <p><strong>Cómo ejercer estos derechos</strong></p>
      <p>Cualquier solicitud para ejercer los derechos del Usuario puede dirigirse al Titular a través de los datos de contacto proporcionados en este documento. Dichas solicitudes tienen carácter gratuito y serán respondidas por el Titular con la mayor antelación posible y siempre en el plazo de un mes, facilitando a los Usuarios la información legalmente exigida. El Propietario comunicará cualquier rectificación o eliminación de Datos personales o restricción de procesamiento a cada destinatario, si lo hubiera, a quien se hayan revelado los Datos personales, a menos que esto resulte imposible o implique un esfuerzo desproporcionado. A petición de los Usuarios, el Titular les informará sobre dichos destinatarios.</p>  
      <p><strong>Información adicional sobre la recopilación y el procesamiento de datos</strong></p>
      <p><strong>Acción legal</strong></p>
      <p>Los Datos Personales del Usuario podrán ser utilizados con fines legales por el Titular en los tribunales o en las etapas previas a posibles acciones legales derivadas del uso indebido de esta Aplicación o de los Servicios relacionados.
El Usuario declara ser consciente de que el Titular puede verse obligado a revelar datos personales a petición de las autoridades públicas.
      </p>
      
      <p><strong>Información adicional sobre los Datos Personales del Usuario</strong></p>
      <p>Además de la información contenida en esta política de privacidad, esta Aplicación puede proporcionar al Usuario información adicional y contextual sobre Servicios particulares o la recopilación y procesamiento de Datos personales previa solicitud.</p>

      <p><strong>Registros y mantenimiento del sistema</strong></p>
      <p>Para fines de operación y mantenimiento, esta Aplicación y cualquier servicio de terceros pueden recopilar archivos que registren la interacción con esta Aplicación (registros del sistema) o utilizar otros Datos personales (como la dirección IP) para este fin.</p>

      <p><strong>Información no contenida en esta política</strong></p>
      <p>Se podrán solicitar al Titular más detalles sobre la recopilación o el procesamiento de Datos Personales en cualquier momento. Consulte la información de contacto al principio de este documento.</p>

      <p><strong>Cambios en esta política de privacidad</strong></p>
      <p>El Propietario se reserva el derecho de realizar cambios a esta política de privacidad en cualquier momento notificando a sus Usuarios en esta página y posiblemente dentro de esta Aplicación y/o - en la medida en que sea técnica y legalmente posible - enviando un aviso a los Usuarios a través de cualquier información de contacto disponible para el Propietario. Se recomienda encarecidamente consultar esta página con frecuencia, consultando la fecha de la última modificación que figura en la parte inferior.</p>

      </div>
    </div>
   
    
  )
}

export default Politicas