import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { color3, } from '../componentes/config.js'
import { FaHelmetSafety } from "react-icons/fa6";
import { color1 } from '../componentes/config.js'

const LineaTiempo=()=> {

  const dibujar=(destino, titulo, subtitulo, img)=>{
    return(
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="white"
        > {destino}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector /><TimelineDot color="primary">
            {img==='1'? <AccountCircleIcon />: <FaHelmetSafety />}
          </TimelineDot><TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span" style={{ color:color3}}>{titulo}</Typography>
          <Typography  style={{ color:color1}}> {subtitulo}</Typography>
        </TimelineContent>
      </TimelineItem>
    )
  }

  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="white"
        >
          Cliente
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <AccountCircleIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>

        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span" style={{ color:color3}}>
            Expone necesidad que requiere solución
          </Typography>
          {/* <Typography>Because you need strength</Typography> */}
        </TimelineContent>
      </TimelineItem>

      {dibujar('Prestador',' Recibe necesida del cliente', ' y envía propuesta de valor','2')}
      {dibujar('Cliente','Evalua propuestas de Prestadores de servicio', ' y toma una desición aceptando una propuesta','1')}
      {dibujar('Prestador','Recibe aceptación de su propuesta', 'Se traslada hasta la dirección del cliente en la fecha y hora indicada','2')}
      {dibujar('Cliente','Espera la llegada del Prestador para dar solución a su necesidad', 'Se identifican ambos y se procede a dar solución a la necesidad','1')}
      {dibujar('Prestador','Realiza su trabajo de forma segura y garantiza la entrega', 'Se realizá verificación del pago por parte del cliente','2')}
      {dibujar('Cliente','Paga la tarifa pactada', 'Califica al Prestador por el servicio prestado!','1')}
      {dibujar('Prestador','Calificación del Cliente', 'Califica su amable atención','2')}
     
    </Timeline>
  );
}

export default LineaTiempo