import img1 from '../assets/gallery1.jpg'
import img2 from '../assets/gallery2.jpg'
import img3 from '../assets/gallery3.jpg'
import img4 from '../assets/conductora.jpeg'
import img5 from '../assets/gallery7.jpg'
import img6 from '../assets/conductora1.jpeg'
import img7 from '../assets/conductora3.webp'

import img10 from '../assets/driver1.jpg'
import img11 from '../assets/driver2.jpg'
import img12 from '../assets/driver3.jpg'
import img13 from '../assets/driver4.jpg'
import img14 from '../assets/driver5.jpg'
import img15 from '../assets/driver6.jpg'


import {Image} from 'react-bootstrap';


const Galeria=()=>{
  return(
    <div style={{ alignContent:'center', alignItems:'center'}} className='p-3'>
       <Image src={img1} style={{'height':200}} />
       <Image src={img2} style={{'height':200}} />
       <Image src={img3} style={{'height':200}} />
       <Image src={img4} style={{'height':200}} />
       <Image src={img6} style={{'height':200}} />
       <Image src={img7} style={{'height':200}} />

       <Image src={img10} style={{'height':200}} />
       <Image src={img11} style={{'height':200}} />
       <Image src={img12} style={{'height':200}} />
       <Image src={img13} style={{'height':200}} />
       <Image src={img14} style={{'height':200}} />
       <Image src={img15} style={{'height':200}} />

    </div>
  )
}

export default Galeria