import { Alert, Button, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { RUTA_SERVER } from '../componentes/config.js';
import Modal from './Modal.jsx'
import { color1 } from '../componentes/config.js'
import Mapa1 from '../componentes/MapView.jsx'

function Registro(){
  const [enviado, setEnviado] = useState(false)
  const [datos, setDatos] = useState({
    Cedula:'',
    Nbres:'',
    Apellidos:'',
    Foto:'',
    Municipio:37,
    Direccion:'',
    Celular:'',
    Correo:'',
    Clave:'',
  });
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState([]);
  const handleClose = () => setShow(false);
   
  const asignarDato =(event)=>{
    event.preventDefault();
    setDatos({...datos, [event.target.name]: event.target.value});
  }
  
  const grabar = async()=>{
    try {
      const idReg = await fetch(RUTA_SERVER+'/setPersona',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
          body: JSON.stringify(datos)
      })

      const ticket = await idReg.json()
      setEnviado(false)
      mostrarMsg(["Felicidades!!!","Gracias por registrase con nosotros",
      "Ahora lo remiteremos a la pantalla de Menú para que pueda solicitar servicios o terminar de diligenciar la información para prestar sus servicios profesionales!.",
      "..."])      

    } catch (error) {
      mostrarMsg(["Ocurrió un problema al Grabar los datos!!"])
    }
  }

  const mostrarMsg = (msg)=>{
    setMsg(msg)
    setShow(true)
  }
  

  const validarDatos=()=>{
    setEnviado(true)
    if(datos.Cedula ===''){
      mostrarMsg(["Por favor digite su número de Cédula!"])
      setEnviado(false)

    }else if(datos.Nbres ===''){
      mostrarMsg(["Por favor digite su Nombre!"])
      setEnviado(false)
  
    }else if(datos.Apellidos ===''){
      mostrarMsg(["Por favor digite su Apellido!"])
      setEnviado(false)
  
    }else if(datos.Municipio ===''){
      mostrarMsg(["Por favor haga Click en el mapa para identificar su ubicación\nSi la ubicada por el sistema no es la suya, no importa, después pdrá actualizarla!"])
      setEnviado(false)
  
    }else if(datos.Direccion===''){
      mostrarMsg(["Por favor digite su Dirección de residencia!"])
      setEnviado(false)
  
    }else if(datos.Celular===''){
      mostrarMsg(["Por favor digite un Número de CeluLar!"])
      setEnviado(false)
  
    }else if(datos.Correo ===''){
      mostrarMsg(["Por favor digite un Correo electrónico!"])
      setEnviado(false)
  
    } else if(datos.Clave ===''){
      mostrarMsg(["Por favor cree una Clave de acceso a la plataforma!"])
      setEnviado(false)

    }else{
      validarCedula()
    }
  }

  const validarCedula = async()=>{
    try {
      const idReg = await fetch(RUTA_SERVER+'/getId',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
          body: JSON.stringify({
            Tabla:'personas',
            Campo:'Cedula',
            Valor: datos.Cedula
          })
      })

      const id = await idReg.json()
      if(id===null){
        grabar()
      }else{
        mostrarMsg(['El número de Cédula ' + datos.Cedula + ', ya se encuentra en nuestro sistema.',
      'Por favor revise los datos o solicite recuperación de su cuenta.'])
      }
      

    } catch (error) {
      mostrarMsg(["Ocurrió un problema al Grabar los datos!!"])
    }
  }

  return(
    <div className='container mt-3 mb-3'>
      <div className="row">
        <h3 style={{ color:'#7ff'}}>REGISTRO DE USUARIOS</h3>
        <p style={{ color:color1}}>Por favor digite todos los datos solicitados en este formulario!</p>
        <hr />
      </div>
  
      <div className="row mb-3">
        <Mapa1  Tamanio={'50'}/>
      </div>

      <hr />
      <div className="row">
        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input 
              type="text" 
              className="form-control" 
              name='Cedula' 
              placeholder="Digite su Número de Cédula" 
              onChange={(event)=> asignarDato(event)} 
            />
            <label htmlFor="Cedula">Digite su Número de Cédula</label>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input 
              type="text" 
              className="form-control" 
              name='Nbres' 
              placeholder="Digite sus Nombres" 
              onChange={(event)=> asignarDato(event)} 
            />
            <label htmlFor="Nbres">Nombres</label>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input 
              type="text" 
              className="form-control" 
              name='Apellidos' 
              placeholder="Digite sus Apellidos" 
              onChange={(event)=> asignarDato(event)} 
            />
            <label htmlFor="Apellidos">Apellidos</label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input 
              type="text" 
              className="form-control" 
              name='Municipio' 
              placeholder="Digite su Municipio" 
              onChange={(event)=> asignarDato(event)} 
            />
            <label htmlFor="Municipio">Municipio de Residencia</label>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input 
              type="text" 
              className="form-control" 
              name='Direccion' 
              placeholder="Digite su Dirección" 
              onChange={(event)=> asignarDato(event)} 
            />
            <label htmlFor="Direccion">Dirección</label>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input 
              type="text" 
              className="form-control" 
              name='Celular' 
              placeholder="Digite su número de Celular" 
              onChange={(event)=> asignarDato(event)} 
            />
            <label htmlFor="Celular">Celular</label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input 
              type="text" 
              className="form-control" 
              name='Correo' 
              placeholder="Digite su Correo Electrónico" 
              onChange={(event)=> asignarDato(event)} 
            />
            <label htmlFor="Correo">Correo Electrónico</label>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="form-floating mb-3">
            <input 
              type="text" 
              className="form-control" 
              name='Clave' 
              placeholder="Cree una clave" 
              onChange={(event)=> asignarDato(event)} 
            />
            <label htmlFor="Clave">Cree una Clave de Acceso</label>
          </div>
        </div>
      </div>

      <hr />
      <div className="row">
        <div className='mb-3'>
          {enviado===false ?
            <Button
              disabled={!enviado===false}
              color="#7ff000"
              onClick={(event) => validarDatos(event)}
            >Registrar mis datos</Button>
          :
            <Button variant="primary" >
              {/* <Button variant="primary" disabled></Button> */}
              Espere un momento por favor. Estamos grabando su información...{' '}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              /></Button>
          }
        </div>  
      </div>
      <Modal show={show} handleClose={handleClose} msg={msg} /> 
    </div> 
  )

}

export default Registro;